import { useEffect, useState } from "react";
import '../css/reviews.css'; 

function ReviewsPage() {
    const [reviews, setReviews] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(`${API_URL}/get_yandex_reviews.php`)
            .then(response => response.json())
            .then(data => setReviews(data))
            .catch(error => console.error("Ошибка загрузки отзывов:", error));
    }, []);

    

    return (
        <div className="reviews-container">
            <div className="reviews-header">
                <h2>Отзывы об организации Nossa</h2>
                <p>Читаем отзывы и оставляем свой!</p>
            </div>

            <div className="reviews-list">
                {reviews.length > 0 ? (
                    reviews.map((review, index) => (
                        // Для каждого отзыва создаём отдельный блок
                        <div key={index} className="review">
                            <div className="review-header">
                                
                                <div className="author-info">
                                    <h3 className="author-name">{review.author || "Аноним"}</h3>
                                    <span className="review-date">{review.date || "Дата неизвестна"}</span>
                                </div>
                            </div>
                            
                            <div className="review-body">
                                <div className="review-rating">
                                    {/* Если рейтинг есть, показываем звезды */}
                                    {review.rating && (
                                        <span className="stars">{'⭐'.repeat(review.rating)}</span>
                                    )}
                                </div>
                                <p className="review-text">{review.text || "Без комментариев"}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Загрузка отзывов...</p>
                )}
            </div>

            {/* Форма для оставления отзыва */}
            <div className="review-form">
                <h3>Оставьте свой отзыв</h3>
                <a
                    href="https://yandex.ru/maps/org/fuchs_nossa/22794557065/reviews/?from=mapframe&ll=56.137438%2C57.931252&z=12"
                    target="_blank"
                    rel="noopener noreferrer"
                 >
                    <button className="leave-review-button">Оставить отзыв</button>
                 </a>
            </div>
        </div>
    );
}

export default ReviewsPage;
