import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import SvgHome from '../../assetsImage/assets/house.svg';
import '../../css/promotion.css';

const PromotionDetail = () => {
    const { id } = useParams();
    const [promotion, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fio, setFio] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const [success, setSuccess] = useState(false);
    
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchProductDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_URL}/promotionid.php?id=${id}`);
                console.log(response.data);
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    setProduct(response.data);
                }
            } catch (err) {
                setError('Ошибка при получении данных: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [id, API_URL]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Проверяем, что все поля заполнены
        if (!fio || !email || !text) {
          alert('Все поля обязательны для заполнения');
          return;
        }
    
        // Отправляем данные на сервер
        try {
          const response = await fetch(`${API_URL}/send_email.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fio, email, text }),
          });
    
          const result = await response.json();
    
          if (response.ok) {
            alert(result.message); // Сообщение об успешной отправке
            setFio('');
            setEmail('');
            setText('');
          } else {
            alert(result.error); // Сообщение об ошибке
          }
        } catch (error) {
          console.error('Ошибка при отправке:', error);
          alert('Произошла ошибка при отправке сообщения');
        }
      };

    if (loading) return <div>Загрузка...</div>;
    if (error) return <div>{error}</div>;
    if (!promotion) return <div>Акция не найдена</div>;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU');
    };

    return (
        <div className="promotion_index">
            <div className="product-section-with-images-navigation">
                <div className="svg-container">
                    <Link to="/"><img src={SvgHome} alt="Home" /></Link>
                </div>
                <div className="svg-container1">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.7071,7l5,5l-5,5" stroke="#90979C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </div>
                <p className="product-title-text-style-navigation">
                    <Link to="/promotions">Акции</Link>
                </p>
                <div className="svg-container1">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.7071,7l5,5l-5,5" stroke="#90979C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </div>
                <p className="product-title-text-style-navigation">
                    <Link to={`/promotions/${id}`}>{promotion.title}</Link>
                </p>
            </div>

            <div className="promotion_blocks_page">
                <h2>{promotion.title}</h2>
                <p>Акция действует до {formatDate(promotion.date)}</p>
                <div className="promotion_block">
                    <div className="photo_form">
                        <img src={`${API_URL}/${promotion.image_path}`} alt={promotion.title} />
                        
                        {/* Форма */}
                        <div className="photo_form_block"> 
                         <form onSubmit={handleSubmit}>
                            <h3>Оставить заявку</h3>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={fio}
                                    placeholder="ФИО*"
                                    onChange={(e) => setFio(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="email"
                                    value={email}
                                    placeholder="E-mail*"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    value={text}
                                    placeholder="Текст"
                                    onChange={(e) => setText(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="button_text_submit_promo">
                                {/* <p >
                                    Нажимая на кнопку «Отправить», я подтверждаю ознакомление с <a href="/terms" className="user-consent-text-markup">Пользовательским соглашением</a> и даю согласие на обработку <a href="/privacy" className="user-consent-text-markup">Персональных данных</a>.
                                </p> */}
                                <button type="submit" className="btn_btn_primary">Отправить</button>
                            </div>
                          </form>
                             <Link to="tel:+79223216360">
                             <button className="btn_btn_primary_2">📞Позвонить</button>
                             </Link>

                        </div>
                        

                        {/* Сообщение об успехе */}
                        {success && <p style={{ color: 'green' }}>Заявка успешно отправлена!</p>}
                    </div>
                    <p className="promotion_block_p">{promotion.description}</p>
                </div>
            </div>
        </div>
    );
};

export default PromotionDetail;
