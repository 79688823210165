import Cookies from "js-cookie";

// Функция для установки куки
export const setCookie = (name, value, days = 7) => {
    Cookies.set(name, value, { expires: days, path: "/" });
};

// Функция для получения куки
export const getCookie = (name) => {
    return Cookies.get(name);
};

// Функция для удаления куки
export const removeCookie = (name) => {
    Cookies.remove(name, { path: "/" });
};
