import React, { useEffect, useState } from 'react';
import '../../css/news.css'; // Импорт CSS стилей
import SvgHome from '../../assetsImage/assets/house.svg';
import vk from '../../assetsImage/assets/icons8-вконтакте.svg';
import tg from '../../assetsImage/assets/icons8-телеграмма-app.svg';
import { Link } from 'react-router-dom';
import viber from '../../assetsImage/assets/icons8-viber.svg';
import whatsapp from '../../assetsImage/assets/icons8-whatsapp.svg';


const PromotionList = () => {
    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        fetch('https://www.nossa-fuchs.ru/api/promotions.php')  // Укажите путь к вашему PHP файлу
        .then(response => response.json())  // Преобразуем ответ в JSON
        .then(data => {
            setPromotions(data);  // Сохранение полученных данных
        })
        .catch(error => {
            console.error("Error fetching promotions:", error);
        });
    }, []);



    // Группируем акции по 2 в каждой подгруппе
    const groupedPromotions = [];
    for (let i = 0; i < promotions.length; i += 2) {
        groupedPromotions.push(promotions.slice(i, i + 2));
    }

    const API_URL = process.env.REACT_APP_API_URL;

    const formatDate = (dateString) => {
        const date = new Date(dateString);  // Преобразуем строку в объект Date
        return date.toLocaleDateString('ru-RU');  // Форматируем дату в виде "31.03.2025"
    }

    const vk_link = process.env.REACT_APP_VK_LINK;
    const tg_link = process.env.REACT_APP_TG_LINK;
    const whatsapp_link = process.env.REACT_APP_WHATSAPP_LINK;
    

    return (
        <main>
            <div className="product-section-with-images-navigation">
                <div className="svg-container">
                    <a href=""><Link to="/"><img src={SvgHome} alt=""/></Link></a>
                </div>
                <div className="svg-container1">
                    <svg viewBox="0 0 24 24" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip2_203_98" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" fill="white" transform="translate(-0.29290000000000305, 0)" />
                            </clipPath>
                        </defs>
                        <g id="::before" data-node-id="203:107" xmlns="http://www.w3.org/2000/svg">
                            <g id="next.svg fill" data-node-id="203:108" clip-path="url(#clip2_203_98)">
                                <g id="next.svg" data-node-id="203:109">
                                    <path id="Vector_2" data-node-id="203:110" d="M9.7071,7l5,5l-5,5" stroke="#90979C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <p className="product-title-text-style-navigation"><a href=""><Link to="/promotions">Акции</Link></a></p>
            </div>
            <div className="catalog-container">
                <h2 className="catalog-title">Наши Акции</h2>
                <div className="product-card-list">
                    {groupedPromotions.length === 0 ? (
                        <p>Загрузка акций...</p>
                    ) : (
                        groupedPromotions.map((promotionGroup, index) => (
                            <div className="product-card-row" key={index}>
                                {promotionGroup.map(promotion => (
                                    <Link to={`/promotions/${promotion.id}`} key={promotion.id} className="news-card-link">
                                        <div className="product-card-list-line-entity">
                                            <div
                                                className="product-card-list-line-entity-card"
                                                style={{
                                                    backgroundImage: `url(${API_URL}/${promotion.image_path})`,
                                                    backgroundSize: 'contain',   // Добавляем правильный синтаксис
                                                }}
                                            >
                                                <h4>До {formatDate(promotion.date)}</h4>
                                                <h3>{promotion.title}</h3>
                                            </div>
                                            <p>{promotion.short_description}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div className="contact-section1">
                    <div className="contact-section">
                      <div className="contacts">
                        <div className="contact-info-section-news">
                          <h2 className="contact-message">Есть вопросы?</h2>
                          <p className="contact-message1">Свяжитесь с нами любым удобным для Вас способом</p>
                        </div>
                        <div className="contact-info-logos">
                          <a href={vk_link} target="_blank"><img src={vk} className="contact-section-banner" alt="Vkontakte" /></a>
                          <a href={tg_link} target="_blank"><img src={tg} className="contact-section-banner" alt="Telegram" /></a>
                         
                          <a href={whatsapp_link} target="_blank"><img src={whatsapp} className="contact-section-banner" alt="Whastsapp" /></a>
                        </div>
                        <div className="contact-info-logos-mobile">
                                     <div className="contact-info-logos-mobile-section">
                                        <a href={vk_link}><img src={vk} className="contact-section-banner" alt="Vkontakte" /></a>
                                        <a href={tg_link}><img src={tg} className="contact-section-banner" alt="Telegram" /></a>
                                    </div>
                                     <div className="contact-info-logos-mobile-section">
                                      
                                        <a href={whatsapp_link}><img src={whatsapp} className="contact-section-banner" alt="Whastsapp" /></a>
                                      </div>
                          </div>
                      </div>
                    </div>
             </div>
        </main>
    );
}

export default PromotionList;
