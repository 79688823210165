import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../../css/kartochkaTovara.css'; 
import SvgHome from '../../assetsImage/assets/house.svg';
import vk from '../../assetsImage/assets/icons8-вконтакте.svg';
import tg from '../../assetsImage/assets/icons8-телеграмма-app.svg';
import viber from '../../assetsImage/assets/icons8-viber.svg';
import whatsapp from '../../assetsImage/assets/icons8-whatsapp.svg';

function PremiumProductsDetail() {
    const { categoryId, productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [specOpen, setSpecOpen] = useState(false);
    const [approvalOpen, setApprovalOpen] = useState(false);
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchProductDetails = async () => {
            setLoading(true); // Устанавливаем статус загрузки
            const response = await fetch(`${API_URL}/get_product_detail.php?productId=${productId}`);
            const data = await response.json();
            console.log(data);  // Добавить для проверки данных, получаемых с сервера
            if (data.error) {
                setError(data.error); // Обработка ошибок
                setLoading(false);
            } else {
                setProduct(data); // Устанавливаем данные о товаре
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId, API_URL]); // Добавляем API_URL в зависимости, чтобы он обновлялся при изменении

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const toggleSpec = () => setSpecOpen(!specOpen);
    const toggleApproval = () => setApprovalOpen(!approvalOpen);
    const toggleRecommendations = () => setRecommendationsOpen(!recommendationsOpen);

    // Ссылки на соцсети
    const vk_link = process.env.REACT_APP_VK_LINK;
    const tg_link = process.env.REACT_APP_TG_LINK;
    const whatsapp_link = process.env.REACT_APP_WHATSAPP_LINK;

    return (
        <main>
            <div className="product-section-with-images-navigation-details">
                <div className="product-section-with-images-navigation-upper">
                    <div className="svg-container">
                        <Link to="/"><img src={SvgHome} alt="Home" /></Link>
                    </div>
                    <div className="svg-container1">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.7071,7l5,5l-5,5" stroke="#90979C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <p className="product-title-text-style-navigation">
                        <Link to="/catalog">Продукты</Link>
                    </p>
                    <div className="svg-container1">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.7071,7l5,5l-5,5" stroke="#90979C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    
                        <p className="product-title-text-style-navigation"><Link to={`/catalog/premium_oil`}>Премиальные масла</Link></p>
                        
                    {product && (
                        <>
                            <div className="svg-container1">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.7071,7l5,5l-5,5" stroke="#90979C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <p className="product-title-text-style-navigation">
                                <Link to={`/catalog/premium_oil/${product.ident}`}>{product.name}</Link>
                            </p>
                        </>
                    )}
                </div>
            </div>

            <div className="catalog-container">
                <h2 className="product-title-tovar">{product.name}</h2>
                <div className="products_catalog_tovar">
                    <div className="product-image-container">
                        <img src={`${API_URL}/${product.image_path}`} alt={product.name} className="product-image" />
                    </div>
                    <div className="product-details">
                        <div className="product-info">
                            <p className="product-description">{product.description}</p>
                        </div>
                        
                        {/* <p className="product-price">{product.price}</p> */}
                    </div>
                </div>
            </div>
             {/* Спецификации, Одобрения и Рекомендации */}
             <div className='product_opisanie_mobile'>
                 <div className="product-specifications">
                    <h3 onClick={toggleSpec}>Спецификации <span className={`arrow ${specOpen ? 'open' : ''}`}>&#9660;</span> </h3>
                    <div className={`collapsible-content ${specOpen ? 'active' : ''}`}>
                        <ul>
                            {product.specifications ? (
                                product.specifications
                                .replace(/"/g, '')  // Убираем все кавычки
                                .split(',')         // Разделяем по запятой
                                .map((spec, index) => (
                                    <li key={index}>{spec.trim()}</li> // Триммим пробелы
                                ))
                            ) : (
                                <li>Спецификации отсутствуют</li>
                            )}
                        </ul>
                    </div>
                </div>

                <div className="product-specifications">
                    <h3 onClick={toggleApproval}>Одобрения <span className={`arrow ${approvalOpen ? 'open' : ''}`}>&#9660;</span></h3>
                    <div className={`collapsible-content ${approvalOpen ? 'active' : ''}`}>
                        <ul>
                            {product.approvals ? (
                                product.approvals
                                .replace(/"/g, '')  // Убираем все кавычки
                                .split(',')
                                .map((approval, index) => (
                                <li key={index}>{approval.trim()}</li>
                                ))
                            ) : (
                                <li>Спецификации отсутствуют</li>
                            )}
                            
                        </ul>
                    </div>
                </div>

                <div className="product-specifications">
                    <h3 onClick={toggleRecommendations}>
                        Рекомендации Fuchs <span className={`arrow ${recommendationsOpen ? 'open' : ''}`}>&#9660;</span>
                    </h3>
                    <div className={`collapsible-content ${recommendationsOpen ? 'active' : ''}`}>
                        <ul>
                            {product.recommendations ? (
                                product.recommendations
                                .replace(/"/g, '')  // Убираем все кавычки
                                .split(',')
                                .map((recommendations, index) => (
                                <li key={index}>{recommendations.trim()}</li>
                                ))
                            ) : (
                                <li>Спецификации отсутствуют</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='product_opisanie'>
                 <div className="product-specifications">
                    <h3 onClick={toggleSpec}>Спецификации <span className={`arrow ${specOpen ? 'open' : ''}`}>&#9660;</span> </h3>
                    <div className={`collapsible-content ${specOpen ? 'active' : ''}`}>
                        <ul>
                            {product.specifications ? (
                                product.specifications
                                .replace(/"/g, '')  // Убираем все кавычки
                                .split(',')         // Разделяем по запятой
                                .map((spec, index) => (
                                    <li key={index}>{spec.trim()}</li> // Триммим пробелы
                                ))
                            ) : (
                                <li>Спецификации отсутствуют</li>
                            )}
                        </ul>
                    </div>
                </div>

                <div className="product-specifications">
                    <h3 onClick={toggleApproval}>Одобрения <span className={`arrow ${approvalOpen ? 'open' : ''}`}>&#9660;</span></h3>
                    <div className={`collapsible-content ${approvalOpen ? 'active' : ''}`}>
                        <ul>
                            {product.approvals ? (
                                product.approvals
                                .replace(/"/g, '')  // Убираем все кавычки
                                .split(',')
                                .map((approval, index) => (
                                <li key={index}>{approval.trim()}</li>
                                ))
                            ) : (
                                <li>Спецификации отсутствуют</li>
                            )}
                            
                        </ul>
                    </div>
                </div>

                <div className="product-specifications">
                    <h3 onClick={toggleRecommendations}>
                        Рекомендации Fuchs <span className={`arrow ${recommendationsOpen ? 'open' : ''}`}>&#9660;</span>
                    </h3>
                    <div className={`collapsible-content ${recommendationsOpen ? 'active' : ''}`}>
                        <ul>
                            {product.recommendations ? (
                                product.recommendations
                                .replace(/"/g, '')  // Убираем все кавычки
                                .split(',')
                                .map((recommendations, index) => (
                                <li key={index}>{recommendations.trim()}</li>
                                ))
                            ) : (
                                <li>Спецификации отсутствуют</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="product-actions-premium">
                {product.document_path && (
                        <div className="info-box-block-tovar">
                            <button onClick={() => window.open(`${API_URL}/${product.document_path}`, '_blank')}>
                                Документация
                            </button>
                        </div>
                    )}

                    <div className='info-box-block-tovar'>
                        <Link to="/contacts">
                            <button>Где приобрести?</button>
                        </Link>
                    </div>
                    <div className='info-box-block-tovar'>
                        <Link to="tel:+79223216360">
                            <button>📞Позвонить</button>
                        </Link>
                    </div>
            </div>
       
    

            <div className="contact-section1">
                <div className="contact-section">
                    <div className="contacts">
                        <div className="contact-info-section-news">
                            <h2 className="contact-message">Есть вопросы?</h2>
                            <p className="contact-message1">Свяжитесь с нами любым удобным для Вас способом</p>
                        </div>
                        <div className="contact-info-logos">
                            <a href={vk_link} target="_blank" rel="noopener noreferrer">
                                <img src={vk} className="contact-section-banner" alt="Vkontakte" />
                            </a>
                            <a href={tg_link} target="_blank" rel="noopener noreferrer">
                                <img src={tg} className="contact-section-banner" alt="Telegram" />
                            </a>
                            
                            <a href={whatsapp_link} target="_blank" rel="noopener noreferrer">
                                <img src={whatsapp} className="contact-section-banner" alt="Whatsapp" />
                            </a>
                        </div>
                        <div className="contact-info-logos-mobile">
                         <div className="contact-info-logos-mobile-section">
                            <a href={vk_link}><img src={vk} className="contact-section-banner" alt="Vkontakte" /></a>
                            <a href={tg_link}><img src={tg} className="contact-section-banner" alt="Telegram" /></a>
                        </div>
                         <div className="contact-info-logos-mobile-section">
                           
                            <a href={whatsapp_link}><img src={whatsapp} className="contact-section-banner" alt="Whastsapp" /></a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default PremiumProductsDetail;
