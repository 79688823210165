import { useEffect, useState, useMemo } from "react";
import '../../css/admin_panel.css'; // Импорт CSS стилей
import '../../css/login.css'; 

const AdminPanel = () => {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortConfig, setSortConfig] = useState({ column: null, order: "asc" });
    const [selectedCategory, setSelectedCategory] = useState("");
    const [error, setError] = useState("");
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [categories, setCategories] = useState([]);
    const [editProduct, setEditProduct] = useState(null);
    const token = localStorage.getItem("token");
    const [documentFile, setDocumentFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [newProduct, setNewProduct] = useState({
        name: "",
        price: "",
        category_ident: "",
        ident: "",
        description: "",
        specifications: "",
        approvals: "",
        recommendations: "",
        document_path: "",
        image_path: "",
        premium: 0,
    });

    const handleEditProduct = (product) => {
        setEditProduct(product);
    };

    const handleCloseEdit = () => {
        setEditProduct(null); // Закрывает модальное окно
    };
    // Обработчик выбора файлов
    const handleFileChange = (e, type) => {
        if (type === "document") setDocumentFile(e.target.files[0]);
        if (type === "image") setImageFile(e.target.files[0]);
    };


    // Функция загрузки файлов на сервер
const uploadFile = async (file, type) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type); // Передаем тип файла

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload.php`, {
            method: "POST",
            body: formData,
        });

        const result = await response.json();
        if (result.error) {
            alert(`Ошибка загрузки файла: ${result.error}`);
            return null;
        }

        return result.file_path; // Сервер должен вернуть путь к загруженному файлу
    } catch (error) {
        console.error("Ошибка загрузки файла:", error);
        return null;
    }
};


    useEffect(() => {
        const fetchProducts = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setError("Токен не найден");
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/products.php`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (!response.ok) {
                    throw new Error(`Ошибка HTTP: ${response.status}`);
                }

                const data = await response.json();
                if (data.error) {
                    setError(data.error);
                } else {
                    setProducts(data);
                    setIsAuthorized(true);
                }
            } catch (error) {
                setError(error.message);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/categories.php`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (!response.ok) {
                    throw new Error(`Ошибка HTTP: ${response.status}`);
                }

                const data = await response.json();
                console.log("Категории с сервера:", data); // Отладка

                // Проверяем, действительно ли это массив, прежде чем обновлять состояние
                if (Array.isArray(data)) {
                    setCategories(data);
                } else {
                    console.error("Ошибка: Ожидался массив, но получено:", typeof data);
                    setCategories([]);
                }
            } catch (error) {
                console.error("Ошибка загрузки категорий:", error);
            }
        };

        
        

        fetchProducts();
        fetchCategories();
       
    }, [token]);

    // Выход (очистка токена)
    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    // Обновление данных формы добавления
    const handleInputChange = (e) => {
        setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
    };

    // Добавление нового товара
    const handleAddProduct = async () => {
        const token = localStorage.getItem("token");
        if (!newProduct.name || !newProduct.price || !newProduct.category_ident) {
            alert("Введите название, цену и выберите категорию");
            return;
        }
    
        // Генерируем ident автоматически, если он не задан
        const generatedIdent = newProduct.name.toLowerCase().replace(/\s+/g, "_");
    
        // Загружаем файлы перед отправкой товара
        const documentPath = await uploadFile(documentFile, "document");
        const imagePath = await uploadFile(imageFile, "image");
    
        const productData = {
            ...newProduct,
            ident: generatedIdent, // Присваиваем ident
            document_path: documentPath || "",
            image_path: imagePath || "",
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/products.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(productData),
            });
    
            const result = await response.json();
            if (result.error) {
                alert(result.error);
            } else {
                setProducts([...products, productData]);
                setNewProduct({
                    name: "",
                    price: "",
                    category_ident: "",
                    ident: "",
                    description: "",
                    specifications: "",
                    approvals: "",
                    recommendations: "",
                    document_path: "",
                    image_path: "",
                    premium: 0,
                });
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    };

    const handleSaveEdit = async (e) => {
        e.preventDefault();  // Предотвращаем перезагрузку страницы
    
        const token = localStorage.getItem("token");
    
        if (!editProduct.name || !editProduct.price || !editProduct.category_ident) {
            alert("Введите название, цену и выберите категорию");
            return;
        }
    
        try {
            // Загружаем файлы перед отправкой обновленного товара
            const documentPath = documentFile ? await uploadFile(documentFile, "document") : editProduct.document_path;
            const imagePath = imageFile ? await uploadFile(imageFile, "image") : editProduct.image_path;
    
            const updatedProduct = {
                ...editProduct,
                document_path: documentPath,
                image_path: imagePath,
            };
    
            console.log("Отправка данных на сервер:", updatedProduct); // Проверка данных перед отправкой
    
            const response = await fetch(`${process.env.REACT_APP_API_URL}/products.php`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedProduct),
            });
    
            const result = await response.json();
            console.log("Ответ сервера:", result);
    
            if (result.error) {
                alert(result.error);
            } else {
                setProducts(products.map((p) => (p.ident === editProduct.ident ? updatedProduct : p)));
                setEditProduct(null);  // Сбрасываем редактируемый товар
                setDocumentFile(null);  // Сбрасываем файлы
                setImageFile(null); 
            }
        } catch (error) {
            console.error("Ошибка сохранения:", error);
        }
    };

    // Удаление товара
    const handleDeleteProduct = async (ident) => {
        const token = localStorage.getItem("token");
        if (!window.confirm("Вы уверены, что хотите удалить товар?")) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/products.php`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ ident }),
            });

            const result = await response.json();
            if (result.error) {
                alert(result.error);
            } else {
                setProducts(products.filter((product) => product.ident !== ident));
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    };

    const filteredProducts = products
    .filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((product) =>
        selectedCategory ? product.category_ident === selectedCategory : true
    );
    

    const sortedProducts = useMemo(() => {
        if (!sortConfig.column) return filteredProducts;
        return [...filteredProducts].sort((a, b) => {
            const aValue = a[sortConfig.column];
            const bValue = b[sortConfig.column];
    
            return sortConfig.order === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        });
    }, [filteredProducts, sortConfig]);
    console.log(sortedProducts)
    
    const handleSort = (column) => {
        console.log("Клик по колонке:", column);
        setSortConfig((prev) => ({
            column,
            order: prev.column === column && prev.order === "asc" ? "desc" : "asc",
            
        }));
        console.log("Клик по колонке:", column.order);
        
    };

    const filteredByCategory = sortedProducts.filter((product) =>
        selectedCategory ? product.category_ident === selectedCategory : true
    );

    const [currentPage, setCurrentPage] = useState(1);
        const itemsPerPage = 5;

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentProducts = filteredByCategory.slice(indexOfFirstItem, indexOfLastItem);

        const totalPages = Math.ceil(filteredByCategory.length / itemsPerPage);

        const handlePageChange = (page) => {
            setCurrentPage(page);
        };

    return (
        <div className="admin-panel">
            <div className="admin-panel-header">
                <h2>Админ-панель</h2>
                <button onClick={handleLogout} className="logout-button">Выйти</button>
            </div>
            
            {error && <p className="error-message">{error}</p>}
    
            {isAuthorized ? (
                <>
                    <div className="admin-panel-add-product">
                        <h3>Добавить товар</h3>
                        <form className="product-form">
                            <div className='product-form-input'>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Название"
                                    value={newProduct.name}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                                <input
                                    type="number"
                                    name="price"
                                    placeholder="Цена"
                                    value={newProduct.price}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                                <textarea
                                    name="description"
                                    placeholder="Описание"
                                    value={newProduct.description}
                                    onChange={handleInputChange}
                                    className="textarea-field"
                                />
                                <textarea
                                    name="specifications"
                                    placeholder="Характеристики"
                                    value={newProduct.specifications}
                                    onChange={handleInputChange}
                                    className="textarea-field"
                                />
                                <textarea className="textarea-field" name="approvals" placeholder="Одобрения" value={newProduct.approvals} onChange={handleInputChange} />
                                <textarea className="textarea-field" name="recommendations" placeholder="Рекомендации" value={newProduct.recommendations} onChange={handleInputChange} />
                             </div>
                             <div className='product-form-doc-img'>
                            <div className="input_field">
                                <h3>Документ</h3>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileChange(e, "document")}
                                    className="file-input"
                                />
                            </div>
                            <div className="input_field">
                            <h3>Фото</h3>
                            <input
                                type="file"
                                onChange={(e) => handleFileChange(e, "image")}
                                className="file-input"
                            />
                            </div>
                            </div>
                            <select
                                name="category_ident"
                                value={newProduct.category_ident}
                                onChange={handleInputChange}
                                className="select-field"
                            >
                                <option value="">Выберите категорию</option>
                                {categories.map((category) => (
                                    <option key={category.ident} value={category.ident}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    name="premium"
                                    checked={!!newProduct.premium}
                                    onChange={(e) => setNewProduct({ ...newProduct, premium: e.target.checked ? 1 : 0 })}
                                    className="checkbox-input"
                                />
                                <p>Премиум товар</p>
                            </label>
                            <button onClick={handleAddProduct} className="submit-button">Добавить</button>
                        </form>
                    </div>
    
                    <h3>Список товаров</h3>
                    <div className="option-table">
                        <input
                            type="text"
                            placeholder="Поиск товара..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-input"
                        />
                        <select onChange={(e) => setSelectedCategory(e.target.value)}>
                            <option value="">Все категории</option>
                            {categories.map((category) => (
                                <option key={category.ident} value={category.ident}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort("name")}>Название</th>
                                <th onClick={() => handleSort("price")}>Цена</th>
                                <th onClick={() => handleSort("category_ident")}>Категория</th>
                                <th>Описание</th>
                                <th>Спецификации</th>
                                <th>Одобрения</th>
                                <th>Рекомендации</th>
                                <th>Премиум</th>
                                <th>Действия</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                          {currentProducts.map((product) => (
                                <tr key={product.ident}>
                                    <td>{product.name}</td>
                                    <td>{product.price} ₽</td>
                                    <td>{categories.find((c) => c.ident === product.category_ident)?.name || "Неизвестно"}</td>
                                    <td>{product.description}</td>
                                    <td>{product.specifications}</td>
                                    <td>{product.approvals}</td>
                                    <td>{product.recommendations}</td>
                                    <td>{product.premium}</td>
                                    <td>
                                        <button onClick={() => handleDeleteProduct(product.ident)} className="action-button">Удалить</button>
                                        <button onClick={() => handleEditProduct(product)} className="action-button">Редактировать</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                       <div className="pagination">
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className="pagination-button"
                                onClick={() => handlePageChange(index + 1)}
                                disabled={currentPage === index + 1}
                            >
                                {index + 1}
                                
                            </button>
                        ))}
                    </div>
                    {editProduct && (
                            <div className="edit-product-modal">
                                <div className="modal-content">
                                <h3>Редактирование товара</h3>
                                <form className="product-form" onSubmit={handleSaveEdit}>
                                    <input
                                        type="text"
                                        name="name"
                                        value={editProduct.name}
                                        onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value } ) }
                                        className="input-field"
                                    />
                                    
                                    <input
                                        type="number"
                                        name="price"
                                        value={editProduct.price}
                                        onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
                                        className="input-field"
                                    />
                                    <textarea
                                        name="description"
                                        value={editProduct.description}
                                        onChange={(e) => setEditProduct({ ...editProduct, description: e.target.value })}
                                        className="textarea-field"
                                    />
                                    <textarea
                                        name="specifications"
                                        placeholder="Спецификации"
                                        value={editProduct.specifications}
                                        onChange={(e) => setEditProduct({ ...editProduct, specifications: e.target.value })}
                                        className="textarea-field"
                                    />
                                    <textarea
                                        name="approvals"
                                        placeholder="Одобрения"
                                        value={editProduct.approvals}
                                        onChange={(e) => setEditProduct({ ...editProduct, approvals: e.target.value })}
                                        className="textarea-field"
                                    />
                                    <textarea
                                        name="recommendations"
                                        placeholder="Рекомендации"
                                        value={editProduct.recommendations}
                                        onChange={(e) => setEditProduct({ ...editProduct, recommendations: e.target.value })}
                                        className="textarea-field"
                                    />
                                    <select
                                        name="category_ident"
                                        value={editProduct.category_ident}
                                        onChange={(e) => setEditProduct({ ...editProduct, category_ident: e.target.value })}
                                        className="select-field"
                                    >
                                        <option value="">Выберите категорию</option>
                                        {categories.map((category) => (
                                            <option key={category.ident} value={category.ident}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="submit" className="submit-button">Сохранить изменения</button>
                                    <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={() => handleCloseEdit()} // Функция закрытия
                                    >Отмена</button>
                                </form>
                                </div>
                            </div>
                        )}
                </>
            ) : (
                <p>Авторизуйтесь для доступа</p>
            )}
        </div>
    );
};

export default AdminPanel;
