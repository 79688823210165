import React, { useEffect, useState } from 'react';
import '../../css/news.css'; // Импорт CSS стилей
import SvgHome from '../../assetsImage/assets/house.svg';
import vk from '../../assetsImage/assets/icons8-вконтакте.svg';
import tg from '../../assetsImage/assets/icons8-телеграмма-app.svg';
import { Link } from 'react-router-dom';
import viber from '../../assetsImage/assets/icons8-viber.svg';
import whatsapp from '../../assetsImage/assets/icons8-whatsapp.svg';


const PromotionSection = () => {
    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        fetch('https://www.nossa-fuchs.ru/api/promotions.php')  // Укажите путь к вашему PHP файлу
        .then(response => response.json())  // Преобразуем ответ в JSON
        .then(data => {
            setPromotions(data);  // Сохранение полученных данных
        })
        .catch(error => {
            console.error("Error fetching promotions:", error);
        });
    }, []);



    // Группируем акции по 2 в каждой подгруппе
    const groupedPromotions = [];
    for (let i = 0; i < promotions.length; i += 2) {
        groupedPromotions.push(promotions.slice(i, i + 2));
    }

    const API_URL = process.env.REACT_APP_API_URL;

    const formatDate = (dateString) => {
        const date = new Date(dateString);  // Преобразуем строку в объект Date
        return date.toLocaleDateString('ru-RU');  // Форматируем дату в виде "31.03.2025"
    }
    

    return (
        groupedPromotions.map((promotionGroup, index) => (
            <div className="news-section-container1">
                
                  {promotionGroup.map((promotion) => (
                    <div key={promotion.id} className="news-item">
                      <Link to={`/promotions/${promotion.id}`}>
                        <div className="blue-gradient-box">
                          <div className="blue-gradient-box-text">
                            <p className="announcement-text-css">{promotion.title}</p>
                            <p className="announcement-text">{promotion.short_description}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
                ))
    );
}

export default PromotionSection;
