import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../css/login.css'; 

const API_URL = process.env.REACT_APP_API_URL;

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const handleLogin = async (e) => {
        e.preventDefault();
        
        const response = await fetch("https://www.nossa-fuchs.ru/api/auth.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();

        if (data.token) {
            localStorage.setItem("token", data.token);
            onLogin(); // Вызываем переданную функцию
      navigate("/admin"); // Перенаправляем в админку
        } else {
            setError(data.error || "Ошибка авторизации");
        }
    };

    return (
        <div className="login-container">
            <h2>Вход в Админ-панель</h2>
            <form onSubmit={handleLogin} className="login-form">
                <input
                    type="text"
                    placeholder="Логин"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="input-field"
                />
                <input
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field"
                />
                <button type="submit" className="submit-button-auth">Войти</button>
            </form>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default Login;
