import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './components/footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import routesData from './routesData';
import ProtectedRoute from './components/PrivateRoute';
import Login from './components/adminPanel/Login'; // Убедись, что импорт верный

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

const AnimatedContent = ({ children }) => {
  const contentProps = useSpring({
    opacity: 1,
    from: { opacity: 0, color: 'black', width: '100%' },
    config: { duration: 1000 },
  });

  return <animated.main style={contentProps}>{children}</animated.main>;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));

  useEffect(() => {
    const checkAuth = () => {
        setIsAuthenticated(!!localStorage.getItem("token"));
    };

    window.addEventListener("storage", checkAuth); // Слушаем изменения в localStorage

    return () => {
        window.removeEventListener("storage", checkAuth);
    };
}, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {routesData.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.private ? (
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  {route.header && <route.header />}
                  <AnimatedContent>
                    <route.component />
                  </AnimatedContent>
                </ProtectedRoute>
              ) : (
                <>
                  {route.header && <route.header />}
                  <AnimatedContent>
                    {route.path === "/admin/login" ? (
                      <Login onLogin={handleLogin} />
                    ) : (
                      <route.component />
                    )}
                  </AnimatedContent>
                </>
              )
            }
          />
        ))}
      </Routes>
      <ScrollToTopButton />
      <Footer />
    </Router>
  );
};

export default App;
